body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.App {
    width: 100%;
    position: absolute;
    z-index: 2;
}

body,
html {
    overflow: hidden;
    margin: 0;
}

.App {
    background: #000;
    color: #fff;
    margin: 0;
}

.App a {
    color: #ff0000;
}

.App a:visited {
    color: #990000;
}

.App h1 {
    font-size: 2.5em;
    padding: 5px 15px 5px 15px;
}

.App .photoIcon {
    width: 40px;
    height: 40px;
}

.photoIcon {
    -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
    filter: invert(100%);
}

.App h1.expanded {
}

.App h1.collapsed {
}

.u-m-b-1 {
    margin-bottom: 15px;
}

.about-container {
    padding: 15px;
}

.links {
    text-align: center;
}

.flexy-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin: 0;
}

.iframe-container {
    -webkit-justify-content: space-around;
            justify-content: space-around;
}

.iframe-container iframe {
}

.collapsed .toggleButton {
}

.header.expanded .titleText {
}

.info-box {
    padding: 5px 15px 5px 15px;
    font-size: 1em;
}

.ar-container {
    width: 100%;
    height: 100%;
    position: fixed;
    overflow: hidden;
    /* width: 100%; */
    /* position: absolute; */
    /* height: 100vh; */
    /* height: calc(var(--vh, 1vh) * 100); */
    /* display: block; */
}

video {
    overflow: hidden;
}

canvas {
    overflow: hidden;
}

